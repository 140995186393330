<template>
  <div>
    <b-card
      no-body
    >
      <div class="card-header">
        <b-card-title>Codigos QR</b-card-title>
        <div class="d-flex gap-2">
          <b-button
            variant="primary"
            @click="downloadImage"
          >
          <div class="d-flex align-items-center justify-content-center gap-1">
            <download-icon />
            <span class="text-nowrap">
              Descargar
            </span>
          </div>
          </b-button>
          <b-button
            variant="primary"
            @click="copyToClipboard"
          >
            <div class="d-flex align-items-center justify-content-center gap-1">
              <copy-icon/>
              <span class="text-nowrap">Copiar</span>
          </div>
          </b-button>
        </div>
      </div>
      <div class="m-2">
        <div class="d-flex align-items-center justify-content-start mb-1 mb-md-0 gap-2">
          <div class="qr-container">
            <b-form-group
              id="input-group-1"
              label="Url:"
              label-for="input-1"
              description="Indique la URL que se codificará en la imagen QR."
            >
              <b-form-input
                id="input-1"
                v-model="url"
                type="text"
                placeholder="https://estelarbet.vip/"
                required
              ></b-form-input>
            </b-form-group>
            <div class="qr" ref="captureQr">
              <qr-code :text="url === '' ? 'https://estelarbet.vip' : url"  />
            </div>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
  import { DownloadIcon, CopyIcon } from 'vue-feather-icons'
  import QrCode from "vue-qrcode-component";
  import html2canvas from "html2canvas";
  import {
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
  } from 'bootstrap-vue'
  export default {
    name: "ContentQR",
    components: {
      BCard,
      BCardTitle,
      BRow,
      BCol,
      BFormInput,
      BFormGroup,
      BButton,
      QrCode,
      DownloadIcon,
      CopyIcon,
    },
    data(){
      return {
        url: ""
      }
    },
    methods: {
      async copyToClipboard() {
        const captureQr = this.$refs.captureQr;

        try {
          const canvas = await html2canvas(captureQr);
          canvas.toBlob((blob) => {
            const item = new ClipboardItem({ "image/png": blob });
            navigator.clipboard.write([item]).then(() => {
              this.$swal.fire({
                toast: true,
                timer: 2000,
                position: "top-end",
                showConfirmButton: false,
                title: "Copia completa!",
                text: "Imagen copiada al portapapeles!",
                icon: "success"
              });
            });
          });
        } catch (error) {
          console.error("Error al copiar al portapapeles:", error);
        }
      },
      async downloadImage() {
        const captureQr = this.$refs.captureQr;

        try {
          const canvas = await html2canvas(captureQr);
          const link = document.createElement("a");
          const time = new Date().getTime()
          link.download = `estelar-qr-${time}.png`;
          link.href = canvas.toDataURL("image/png");
          link.click();
        } catch (error) {
          console.error("Error al descargar la imagen:", error);
        }
      },
    },
    setup() {
      return {}
    },
  }
</script>


<style scoped>
.card-header {
  display: flex;
  justify-content: space-between;
}

.qr-container {
  margin: auto;
  width: 80%;
}

.qr {
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  margin: auto;
  width: 60%;
  max-width: 400px;

}

.qr /deep/ img {
  width: 100% !important;
}
</style>
