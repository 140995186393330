import WebClientsRequest from '@/@api/webClients'
import { ref, computed } from '@vue/composition-api'
// import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { debouncedWatch } from '@vueuse/core'

const countries = ref([])

export default function useWebClients() {
  // Use toast
  const toast = useToast()

  const refCountryListTable = ref(null)

  // const countries = ref([])

  // Table Handlers
  const tableColumns = [
    {
      key: 'id',
      label: '#',
      sortable: true,
      thStyle: { width: '80px' },
    },
    {
      label: 'User ID',
      key: 'reference',
      sortable: true,
      thStyle: { width: '140px' },
    },
    {
      label: 'Nombre',
      key: 'first_name',
      sortable: true,
    },
    {
      label: 'Apellido',
      key: 'last_name',
      sortable: true,
    },
    {
      label: 'País',
      key: 'country',
      sortable: true,
    },
    { label: 'Alertas', key: 'alerts' },
    {
      label: 'Acciones',
      key: 'actions',
      thStyle: { width: '160px' },
    },
  ]
  const perPage = ref(10)
  const totalCountries = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refCountryListTable.value ? refCountryListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCountries.value,
    }
  })

  const refetchData = () => {
    refCountryListTable.value.refresh()
  }

  debouncedWatch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  }, { debounce: 400 })

  const fetchCountries = (ctx, callback) => {
    WebClientsRequest.list({
      params: {
        q: searchQuery.value,
        paginate: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      },
    })
      .then(response => {
        const { data, total } = response.data.data

        callback(data.map(country => ({ ...country, loading: false })))
        totalCountries.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching user list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  async function fetchCountriesSelector(isMemo = false) {
    if (isMemo && countries.value.length) {
      return Promise.resolve(countries.value)
    }

    try {
      const { data } = await WebClientsRequest.select()

      const list = data.data.map(country => ({
        id: country.id,
        value: country.iso?.toUpperCase() || country.id,
        label: country.name,
      }))

      countries.value = list

      return list
    } catch (error) {
      throw new Error(error)
    }
  }

  async function countryStore(body) {
    try {
      const { data } = await WebClientsRequest.store(body)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function countryUpdate(id, body) {
    try {
      const { data } = await WebClientsRequest.update(id, body)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function countryDelete(id) {
    try {
      const { data } = await WebClientsRequest.destroy(id)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  fetchCountriesSelector(true).then(list => {
    countries.value = list
  })

  const resolveIsoCountry = iso => {
    if (!iso) return ''

    const dic = countries.value.reduce((acc, country) => {
      acc[country.value?.toUpperCase()] = country.label

      return acc
    }, {})

    return dic[iso] ?? iso
  }

  const resolveIsoLanguage = iso => {
    if (!iso) return ''
    if (iso.toUpperCase() === 'ES') return 'Español'
    if (iso.toUpperCase() === 'EN') return 'Ingles'
    if (iso.toUpperCase() === 'AR') return 'Armenio'
    return iso
  }

  const resolveResolution = resolution => {
    if (!resolution) return ''
    if (resolution.toUpperCase() === 'MOBILE') return 'Móvil'
    if (resolution.toUpperCase() === 'TABLET') return 'Tablet'
    if (resolution.toUpperCase() === 'DESKTOP') return 'Escritorio'
    return resolution
  }

  return {
    countries,
    fetchCountries,
    fetchCountriesSelector,
    tableColumns,
    perPage,
    currentPage,
    totalCountries,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCountryListTable,

    resolveIsoCountry,
    resolveIsoLanguage,
    resolveResolution,

    refetchData,
    countryStore,
    countryUpdate,
    countryDelete,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
